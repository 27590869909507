import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import NotificationDialog from "components/NotificationDialog/NotificationDialog";
import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

const CreateSendAppsNotification = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState("");
  const [input, setInput] = useState({
    appsNotificationID: "",
    type: "",
    status: 0
  });
  const [isOpen, setIsOpen] = useState(false);

  const createSendAppsNotificationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => createSendAppsNotificationSignal.current.abort();
  }, []);

  const handleNotificationIDChanged = data => {
    setSelectedNotification(data.name);
    setInput({
      ...input,
      appsNotificationID: data.id
    });
    setIsOpen(false);
  };

  const handleNotificationTypeChanged = data =>
    setInput({
      ...input,
      type: data.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    bookingApi
      .post("SendAppsNotificationTransactions", input, {
        signal: createSendAppsNotificationSignal.current.signal
      })
      .then(resp => {
        console.log(resp);
        setRedirect(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/SendAppsNotification/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Create Apps Notification</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <TextField
                className={classes.option}
                value={selectedNotification}
                label={"Notification"}
                variant="standard"
                onClick={() => {
                  setIsOpen(true);
                }}
                fullWidth={true}
              />
              <NotificationDialog
                onSelect={handleNotificationIDChanged}
                openModal={isOpen}
                setOpenModal={val => {
                  setIsOpen(val);
                }}
              />
              <FormControl
                style={{ cursor: "pointer" }}
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Notification Type
                </InputLabel>
                <Select
                  MenuProps={{
                    classes: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={input.type ?? ""}
                  onChange={handleNotificationTypeChanged}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={"All"}
                    value={"All"}
                  >
                    <b>{`All`}</b>
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={"SpecificUsers"}
                    value={"SpecificUsers"}
                  >
                    <b>{`Specific Users`}</b>
                  </MenuItem>
                </Select>
              </FormControl>

              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateSendAppsNotification;

CreateSendAppsNotification.propTypes = {
  history: PropTypes.object
};
