import NotificationsIcon from "@material-ui/icons/Notifications";
import AppsNotificationSection from "views/AppsNotificationSection/AppsNotificationSection";
import CreateAppsNotificationSection from "views/AppsNotificationSection/CreateAppsNotificationSection";
import DeleteAppsNotificationSection from "views/AppsNotificationSection/DeleteAppsNotificationSection";
import ViewAppsNotificationSection from "views/AppsNotificationSection/ViewAppsNotificationSection";

const appsNotificationSection = () => {
  let views = [];

  views.push({
    path: "/AppsNotificationSection/List",
    name: "Apps Notification Section",
    icon: NotificationsIcon,
    component: AppsNotificationSection,
    layout: "/admin"
  });

  return views;
};

const appsNotificationSectionHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/AppsNotificationSection/Create",
      name: "Create Apps Notification Section",
      component: CreateAppsNotificationSection,
      layout: "/admin"
    },
    {
      path: "/AppsNotificationSection/View",
      name: "View Apps Notification Section",
      component: ViewAppsNotificationSection,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/AppsNotificationSection/Delete",
      name: "Delete Apps Notification Section",
      component: DeleteAppsNotificationSection,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Apps Notification Section Hidden",
        state: "appsNotificationSectionHidden",
        hideInSidebar: true,
        views: views
      }
    ];
};

export { appsNotificationSection, appsNotificationSectionHidden };
