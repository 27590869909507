import NotificationsIcon from "@material-ui/icons/Notifications";
import CompleteAppsNotification from "views/SendAppsNotification/CompleteAppsNotification";
import CreateSendAppsNotification from "views/SendAppsNotification/CreateSendAppsNotification";
import DeleteAppsNotification from "views/SendAppsNotification/DeleteAppsNotification";
import EditAppsNotification from "views/SendAppsNotification/EditAppsNotification";
import InDraftAppsNotification from "views/SendAppsNotification/InDraftAppsNotification";
import InProcessAppsNotification from "views/SendAppsNotification/InProcessAppsNotification";
import SubmitSendAppsNotification from "views/SendAppsNotification/SubmitSendAppsNotification";
import ViewAppsNotification from "views/SendAppsNotification/ViewAppsNotification";

const sendAppsNotification = () => {
  let views = [];

  views = views.concat([
    {
      path: "/SendAppsNotification/InDraft",
      name: "In Draft",
      mini: "IN",
      component: InDraftAppsNotification,
      layout: "/admin"
    },
    {
      path: "/SendAppsNotification/InProcess",
      name: "In Process",
      mini: "IP",
      component: InProcessAppsNotification,
      layout: "/admin"
    },
    {
      path: "/SendAppsNotification/Complete",
      name: "Complete",
      mini: "CM",
      component: CompleteAppsNotification,
      layout: "/admin"
    }
  ]);

  return [
    {
      collapse: true,
      name: "SendNotification",
      icon: NotificationsIcon,
      state: "sendNotificationCollapse",
      views: views
    }
  ];
};

const sendAppsNotificationHidden = () => {
  let views = [
    {
      path: "/SendAppsNotification/Create",
      name: "Create Apps Notification Data",
      component: CreateSendAppsNotification,
      layout: "/admin"
    },
    {
      path: "/SendAppsNotification/View",
      name: "View Apps Notification Data",
      component: ViewAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/SendAppsNotification/Edit",
      name: "Edit Apps Notification Data",
      component: EditAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/SendAppsNotification/Delete",
      name: "Delete Apps Notification Data",
      component: DeleteAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/SendAppsNotification/Submit",
      name: "Submit Apps Notification Data",
      component: SubmitSendAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ];

  return [
    {
      collapse: true,
      name: "Merchant Hidden",
      state: "merchantHidden",
      hideInSidebar: true,
      views: views
    }
  ];
};

export { sendAppsNotification, sendAppsNotificationHidden };
