import { Assignment, Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput.js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import ReactTable from "react-table";
import { formatTripDate } from "functions/Helper";
import { formatDateTime } from "functions/Helper";
import { formatDate } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function Detail(props) {
  const sortOptionsList = [
    "IDENTIFICATION_NO_ASC",
    "IDENTIFICATION_NO_DESC",
    "IDENTIFICATION_FULLNAME_ASC",
    "IDENTIFICATION_FULLNAME_DESC"
  ];
  const [state, setState] = useState(null);
  const [totalPaxes, setTotalPaxes] = useState(0);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    sort: null
  });
  const [passengerData, setPassengerData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const { id } = useParams();

  const viewOnlineBookingsPromoCodeSignal = React.useRef(new AbortController());

  const getData = () => {
    bookingApi
      .get("OnlineBookings/" + id, {
        signal: viewOnlineBookingsPromoCodeSignal.current.signal,
        params: {
          ID: id
        }
      })
      .then(data => setState(data))
      .catch(error => console.log(error));
  };

  const getPassengerData = () => {
    setLoading(true);
    bookingApi
      .get("OnlineBookings/" + id + "/Details", {
        signal: viewOnlineBookingsPromoCodeSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination,
          bookingID: id
        }
      })
      .then(data => {
        setPassengerData(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setTotalPaxes(data.totalRecords);
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPassengerData();
  }, [filter, pagination]);

  useEffect(() => {
    getData();
    return () => {
      viewOnlineBookingsPromoCodeSignal.current.abort();
    };
  }, []);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleSortOptionsChanged = e => {
    setFilter({ ...filter, sort: e.target.value });
  };

  const classes = useStyles();

  return (
    <GridContainer>
      {state !== null && (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>VIEW ONLINE BOOKING</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="No"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.no ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Confirmation Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.confirmationCode ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Transaction Time"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(state.transactionTime + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.user.name ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.user.email ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Contact Number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.user.contactNumber ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Departure Route"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.departureCoreApiTrip?.route?.name ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Departure Date"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatTripDate(state.departureCoreApiTrip.date)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <CustomInput
                    disabled
                    labelText="Departure Time"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.departureCoreApiTrip.time ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={state.isRoundTrip}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                          disabled: true
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is Round Trip"
                  />
                </GridItem>
                {state.isRoundTrip && (
                  <>
                    <GridItem xs={12} md={12}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={state.isReturnTripOpen}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                              disabled: true
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Is Return Trip Open"
                      />
                    </GridItem>
                    {!state.isReturnTripOpen && (
                      <>
                        <GridItem xs={12} md={4}>
                          <CustomInput
                            disabled
                            labelText="Return Route"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: state.returnCoreApiTrip.route.name ?? ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} md={4}>
                          <CustomInput
                            disabled
                            labelText="Return Date"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: formatTripDate(
                                state.returnCoreApiTrip.date
                              )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} md={4}>
                          <CustomInput
                            disabled
                            labelText="Return Time"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: state.returnCoreApiTrip.time ?? ""
                            }}
                          />
                        </GridItem>
                      </>
                    )}
                  </>
                )}
                <GridItem xs={12} md={12}>
                  <CustomInput
                    disabled
                    labelText="Total Pax"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: totalPaxes
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    disabled
                    labelText="Promo Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.onlineBookingPromoCode?.promoCode ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    disabled
                    labelText="Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.currency?.code ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={3}>
                  <CustomInput
                    disabled
                    labelText="Total"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.total ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={3}>
                  <CustomInput
                    disabled
                    labelText="Promo Code Discount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.promoCodeDiscount ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={3}>
                  <CustomInput
                    disabled
                    labelText="Voucher Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.voucherAmount ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={3}>
                  <CustomInput
                    disabled
                    labelText="Grand Total"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.grandTotal ?? ""
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Passengers</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    SORT BY
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={filter.sort ?? ""}
                    onChange={handleSortOptionsChanged}
                  >
                    {sortOptionsList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record}
                          key={record}
                        >
                          <p>{record}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value: filter.searchString ?? ""
                  }}
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={passengerData}
              filterable={false}
              columns={[
                {
                  id: "no",
                  Header: "No",
                  accessor: d => d.identification.no
                },
                {
                  id: "fullName",
                  Header: "Full Name",
                  accessor: d => d.identification.fullName
                },
                {
                  id: "nationality",
                  Header: "Nationality",
                  accessor: d => d.identification.nationality.name
                },
                {
                  id: "issuanceCountry",
                  Header: "Issuance Country",
                  accessor: d => d.identification.issuanceCountry.name
                },
                {
                  id: "issueDate",
                  Header: "Issue Date",
                  accessor: d =>
                    formatDate(new Date(d.identification.issueDate))
                },
                {
                  id: "expiryDate",
                  Header: "Expiry Date",
                  accessor: d =>
                    formatDate(new Date(d.identification.expiryDate))
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
