export function GetOnlineBookingPaymentMethods() {
  return [
    {
      key: 0,
      description: "Card"
    },
    {
      key: 1,
      description: "Grab"
    },
    {
      key: 2,
      description: "Midtrans"
    }
  ];
}

export function GetOnlineBookingPaymentMethodDescription(value) {
  let onlineBookingPaymentMethod = GetOnlineBookingPaymentMethods().find(
    item => item.key === value
  );
  return onlineBookingPaymentMethod.description;
}
