import axios from "axios";

function getHeaders() {
  let headers = {};
  if (localStorage.getItem("Authorization") !== null)
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization")
    };
  return headers;
}

const cancelTokenSource = () => axios.CancelToken.source();

const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL
});

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL,
  headers: getHeaders()
});

const bookingApi = axios.create({
  baseURL: process.env.REACT_APP_BOOKING_API_URL,
  headers: getHeaders()
});

const masterApi = axios.create({
  baseURL: process.env.REACT_APP_MASTER_API_URL,
  headers: getHeaders()
});

const agentApi = axios.create({
  baseURL: process.env.REACT_APP_AGENT_API_URL,
  headers: getHeaders()
});

const voucherApi = axios.create({
  baseURL: process.env.REACT_APP_VOUCHER_API_URL,
  headers: getHeaders()
});

const creditMonitoringApi = axios.create({
  baseURL: process.env.REACT_APP_CREDIT_MONITORING_API_URL,
  headers: getHeaders()
});

const orderApi = axios.create({
  baseURL: process.env.REACT_APP_ORDER_API_URL,
  headers: getHeaders()
});

const counterApi = axios.create({
  baseURL: process.env.REACT_APP_COUNTER_API_URL,
  headers: getHeaders()
});

const counterIssuanceApi = axios.create({
  baseURL: process.env.REACT_APP_COUNTER_ISSUANCE_API_URL,
  headers: getHeaders()
});

const posApi = axios.create({
  baseURL: process.env.REACT_APP_POS_API_URL,
  headers: getHeaders()
});

const travelApi = axios.create({
  baseURL: process.env.REACT_APP_MERCHANT_API_URL,
  headers: getHeaders()
});

const responseInterceptor = response => {
  return response.data.data;
};

const requestInterceptor = request => {
  if (request.params !== undefined) {
    Object.keys(request.params).map(val => {
      if (typeof request.params[val] === "object") {
        request.params[val] = JSON.stringify(request.params[val]);
      }
    });
  }

  return request;
};

// const errorRequestHandler = error => {
//   // console.log("ini errror request", error);
//   return Promise.reject(error);
// };

const errorResponseHandler = error => {
  if (
    error.message !== "canceled" &&
    error.message !== "cancelled" &&
    error !== "canceled"
  ) {
    if (error.response !== undefined) {
      if (error.response.status === 401) return Promise.reject(error);

      if (error.response.data !== undefined) {
        if (error.response.data.exception) {
          alert(JSON.stringify(error.response.data.exception.message));
        } else {
          alert(JSON.stringify(error.response.data.errors));
        }
      } else alert(JSON.stringify(error.response));
    } else {
      alert(JSON.stringify(error));
    }
  }
  return Promise.reject(error);
};

adminApi.interceptors.request.use(requestInterceptor);
bookingApi.interceptors.request.use(requestInterceptor);
masterApi.interceptors.request.use(requestInterceptor);
agentApi.interceptors.request.use(requestInterceptor);
voucherApi.interceptors.request.use(requestInterceptor);
creditMonitoringApi.interceptors.request.use(requestInterceptor);
orderApi.interceptors.request.use(requestInterceptor);
counterApi.interceptors.request.use(requestInterceptor);
counterIssuanceApi.interceptors.request.use(requestInterceptor);
travelApi.interceptors.request.use(requestInterceptor);

adminApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
bookingApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
masterApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
agentApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
voucherApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
creditMonitoringApi.interceptors.response.use(
  responseInterceptor,
  errorResponseHandler
);
orderApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
counterApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
counterIssuanceApi.interceptors.response.use(
  responseInterceptor,
  errorResponseHandler
);
posApi.interceptors.response.use(responseInterceptor, errorResponseHandler);
travelApi.interceptors.response.use(responseInterceptor, errorResponseHandler);

export {
  coreApi,
  adminApi,
  bookingApi,
  masterApi,
  agentApi,
  voucherApi,
  creditMonitoringApi,
  orderApi,
  counterApi,
  counterIssuanceApi,
  posApi,
  travelApi,
  cancelTokenSource
};
